@import url(https://fonts.googleapis.com/css?family=Monoton);
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: radial-gradient(ellipse at top, purple, rgb(53, 2, 53), rgb(4, 0, 24), #1a1a1a);
  margin: 0;
  padding: 16px;
  /* box-shadow: inset 0 0 10vw whitesmoke, inset 5vw 0 12vw red, inset -5vw 0 12vw blue, inset -5vw 0 20vw red,
    inset 5vw 0 20vw blue; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
h1 {
  font-family: 'Monoton';
  font-weight: 100;
  font-size: 80px;
  line-height: 60px;
  text-align: center;
  color: white;
  text-shadow: 0 5px 5px white, 0 0 10px white, 0 0 20px white, 0 0 30px white, 0 0 40px #f0f, 0 0 50px #f0f,
    0 0 60px #f0f, 0 0 50vw blue;
  /* box: radial-gradient(ellipse at top, rgb(218, 31, 218), #333, #333); */
  box-shadow: 0 0 1000px purple;
}

p {
  margin: 0;
  color: #f5f5f5;
  font-size: 14px;
  line-height: 21px;
}

